import React, { useState, useEffect } from 'react';

interface TopicItem {
  id: number;
  text: string;
}

function TopicsList() {
  const [topics, setTopics] = useState<TopicItem[]>([]);
  const url = '';

  useEffect(() => {
    const fetchtopics = async () => {
      try {
        const response = await fetch(`${url}/topics.json`);
        const data = await response.json();
        setTopics(data);
      } catch (error) {
        console.error('Failed to fetch topics', error);
      }
    };

    fetchtopics();
  }, []);
  return (
    <div>
      <div className="custom-border-slant absolute mx-5 w-5/12 border-2 border-corporate-color px-9"></div>
      <div className="mx-5 rounded-lg border-2 bg-white px-9 shadow-md">
        <h3
          className="mb-4
                text-base
                font-semibold
                phone:text-base
                tablet:text-lg
                desktop:text-xl"
        >
          TOPICS
        </h3>
        <ul>
          {topics.map((topic) => (
            <li key={topic.id} className="mb-2 flex">
              <span className="mr-2 text-corporate-color">•</span>
              <p
                className="          
                    text-xs
                    text-gray-700
                    phone:text-xs
                    tablet:text-sm
                    desktop:text-base
                    "
              >
                {topic.text}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default TopicsList;
