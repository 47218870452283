import React, { useState, useEffect } from 'react';

interface NewsItem {
  id: number;
  date: string;
  text: string;
}

function NewsList() {
  const [news, setNews] = useState<NewsItem[]>([]);
  const url = '';

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch(`${url}/news.json`);
        const data = await response.json();
        setNews(data);
      } catch (error) {
        console.error('Failed to fetch news', error);
      }
    };

    fetchNews();
  }, []);
  return (
    <div>
      <div className="custom-border-slant absolute mx-5 w-5/12 border-2 border-corporate-color px-9"></div>
      <div className="mx-5 rounded-lg border-2 bg-white px-9 shadow-md">
        <h3
          className="mb-4
                text-base
                font-semibold
                phone:text-base
                tablet:text-lg
                desktop:text-xl"
        >
          NEWS
        </h3>
        <dl>
          {news.map((item) => (
            <div
              key={item.id}
              className="
                    flex
                    gap-10
                    text-xs
                    text-gray-700
                    phone:text-xs
                    tablet:text-sm
                    desktop:text-base
                    "
            >
              <dt>{item.date}</dt>
              <dd key={item.id} className="mb-2 flex">
                {item.text}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}

export default NewsList;
