import { Helmet } from 'react-helmet-async';
import Header from '../Header';
import { Footer } from '../Footer';
import usePageTracking from '../../hooks/usePageTracking';


const AboutPage: React.FC = () => {
  usePageTracking();
  return (
    <div className="App bg-white text-gray-800">
      <Header />
      <Helmet>
        <title>
          プライバシーポリシー&nbsp;-&nbsp;株式会社クリエイティブ・イノベーションズ
        </title>
        <meta name="description" content="プライバシーポリシー" />
      </Helmet>
      <div className="mb-10 bg-white text-gray-700">
        <div className="container mx-auto px-4">
          <div className="border-b-2 border-gray-200 py-6 text-center">
            <h2
              className="
            font-semibold
            text-xl 
            text-corporate-color
            phone:text-xl
            tablet:text-2xl
            desktop:text-3xl"
            >
              <span className="text-corporate-color">プライバシーポリシー</span>
            </h2>
          </div>

          <div
            className="mt-4 rounded-lg bg-gray-100 p-6 shadow
            text-sm  
            phone:text-sm
            tablet:text-base
            desktop:text-lg
          "
          >
            <p className="large mb-4">
              株式会社クリエイティブ・イノベーションズ（以下当社）では、個人情報に関する法令およびその他の規範を遵守し、お客様の大切な個人情報の保護に万全を尽くします。
            </p>
            <h3
              className="
                mb-2 
                font-semibold
                text-base
                phone:text-base
                tablet:text-lg
                desktop:text-xl            
                "
            >
              個人情報の収集について
            </h3>
            <p>
              当社では、次のような場合に必要な範囲で個人情報を収集することがあります。
            </p>
            <ul className="my-2 ml-5 list-disc">
              <li>当社へのお問い合わせ時</li>
              <li>当社への採用エントリー時</li>
              <li>当社へのパートナー登録時</li>
            </ul>
            <hr className="my-4" />

            <h3
              className="
                mb-2 
                font-semibold
                text-base
                phone:text-base
                tablet:text-lg
                desktop:text-xl            
                "
            >
              個人情報の利用目的について
            </h3>
            <p>
              当社は、お客様から収集した個人情報を次の目的で利用いたします。
            </p>
            <ul className="my-2 ml-5 list-disc">
              <li>お客様への連絡のため</li>
              <li>お客様からのお問い合わせに対する回答のため</li>
              <li>お客様へのサービス提供のため</li>
            </ul>
            <hr className="my-4" />

            <h3
              className="
                mb-2 
                font-semibold
                text-base
                phone:text-base
                tablet:text-lg
                desktop:text-xl            
                "
            >
              個人情報の第三者への提供について
            </h3>
            <p>
              当社では、お客様より取得した個人情報を第三者に開示または提供することはありません。
              <br />
              ただし、次の場合は除きます：
            </p>
            <ul className="my-2 ml-5 list-disc">
              <li>ご本人の同意がある場合</li>
              <li>警察からの要請など、官公署からの要請の場合</li>
              <li>法律の適用を受ける場合</li>
            </ul>
            <hr className="my-4" />

            <h3
              className="
                mb-2 
                font-semibold
                text-base
                phone:text-base
                tablet:text-lg
                desktop:text-xl            
                "
            >
              個人情報の開示、訂正等について
            </h3>
            <p>
              当社は、お客様ご本人からの自己情報の開示、訂正、削除等のお求めがあった場合は、確実に応じます。
            </p>
            <hr className="my-4" />

            <h3
              className="
                mb-2 
                font-semibold
                text-base
                phone:text-base
                tablet:text-lg
                desktop:text-xl            
                "
            >
              個人情報保護に関するお問い合わせ先
            </h3>
            <p>
              e-mail:{' '}
              <a
                href="mailto:info@creative-innovations.co.jp"
                className="text-blue-500"
              >
                info@creative-innovations.co.jp
              </a>
              <br />
              株式会社クリエイティブ・イノベーションズ 法務担当
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutPage;
