import { useParams, useNavigate } from 'react-router-dom';
import ContactForm from './contents/ContactPage';
import AccessPage from './contents/AccessPage';
import PolicyPage from './contents/PolicyPage';
import AboutPage from './contents/AboutPage';
import ServicesPage from './contents/ServicesPage';

const ModalPage = () => {
  const { id } = useParams<'id'>();
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/'); // ルートURLに戻る
  };

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
        <div className="relative mx-auto w-11/12 max-w-5xl rounded-md bg-white shadow-xl">
          <div className="flex justify-end p-2">
            <button
              onClick={handleClose}
              type="button"
              className="ml-auto inline-flex 
                    items-center rounded-lg bg-transparent p-1.5 text-sm 
                    text-gray-400 hover:bg-gray-200 hover:text-gray-900"
            >
              <img
                src="/images/close-icon.svg"
                alt="Close"
                className="h-5 w-5"
              />
            </button>
          </div>
          <div className="max-h-[80vh] overflow-y-auto">
            {id === 'about' && <AboutPage />}
            {id === 'services' && <ServicesPage />}
            {id === 'policy' && <PolicyPage />}
            {id === 'contact' && <ContactForm />}
            {id === 'access' && <AccessPage />}
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalPage;
