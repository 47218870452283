import { useNavigate } from 'react-router-dom';

const menuItems = [
  {
    label: '会社概要',
    id: 'about',
    positionClass: '-translate-y-1.7-full',
    hoverClass: 'hover:bg-red-100 hover:animate-red-light-sweep',
  },
  {
    label: 'サービス',
    id: 'services',
    positionClass: '-translate-x-1.5-full -translate-y-0.7-full',
    hoverClass: 'hover:bg-green-100 hover:animate-green-light-sweep',
  },
  {
    label: '会社方針',
    id: 'policy',
    positionClass: '-translate-y-0.7-full translate-x-1.5-full',
    hoverClass: 'hover:bg-blue-100 hover:animate-blue-light-sweep',
  },
  {
    label: 'お問い合せ',
    id: 'contact',
    positionClass: '-translate-x-full translate-y-1.3-full',
    hoverClass: 'hover:bg-yellow-100 hover:animate-yellow-light-sweep',
  },
  {
    label: 'アクセス',
    id: 'access',
    positionClass: 'translate-y-1.3-full translate-x-full',
    hoverClass: 'hover:bg-orange-100 hover:animate-orange-light-sweep',
  },
];

const Menu = () => {
  const navigate = useNavigate();
  const handleOpenModal = (id: string) => {
    navigate(`/${id}`);
  };

  return (
    <>
      <div
        className="relative flex flex-wrap items-center justify-center
            px-10 
            py-32
            phone:py-32
            tablet:py-40
            desktop:py-44
            overflow-hidden"
      >
        {menuItems.map((item) => (
          <div
            key={item.id}
            className={`
              absolute
              ${item.positionClass}
            `}
          >
            <button
              onClick={() => handleOpenModal(item.id)}
              className={`bg-white flex
              h-phone-custom-ellipse
              w-phone-custom-ellipse
                items-center
                justify-center
                rounded-custom-ellipse
                border-2
                border-corporate-color
                phone:h-phone-custom-ellipse 
                phone:w-phone-custom-ellipse
                text-xs
                phone:text-sm
                tablet:h-tablet-custom-ellipse 
                tablet:w-tablet-custom-ellipse 
                tablet:text-sm
                desktop:h-custom-ellipse 
                desktop:w-custom-ellipse
                desktop:text-base
                ${item.hoverClass}
              `}
            >
              {item.label}
            </button>
          </div>
        ))}
        <div className="p-4 text-center">
          <h2
            className="
              mr-4
              ml-6
              text-left
              text-sm 
              font-semibold
              text-corporate-color 
              phone:mr-4
              phone:ml-6
              phone:text-sm
              tablet:mr-8
              tablet:ml-14
              tablet:text-xl
              desktop:mr-10
              desktop:ml-20 
              desktop:text-2xl"
          >
            <p className="mb-2">業界のトレンド対策と</p>
            <p className="mb-2">自由な発想で</p>
            <p>システム設計をサポート</p>
          </h2>
        </div>
      </div>
    </>
  );
};

export default Menu;
