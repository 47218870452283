import { Helmet } from 'react-helmet-async';

import usePageTracking from '../../hooks/usePageTracking';

const PolicyPage: React.FC = () => {
  usePageTracking();
  return (
    <>
      <Helmet>
        <title>
          会社方針&nbsp;-&nbsp;株式会社クリエイティブ・イノベーションズ
        </title>
        <meta name="description" content="会社方針" />
      </Helmet>
      <div className="bg-white text-gray-700">
        <div className="container mx-auto mt-1 px-4">
          <div className="border-b-4 border-corporate-color py-6 text-center">
            <h2
              className="text-xl
                    font-semibold
                    text-corporate-color
                    phone:text-xl
                    tablet:text-2xl
                    desktop:text-3xl"
            >
              <span>会社方針</span>
            </h2>
          </div>
          <div className="mb-12 mt-4 rounded-lg bg-white p-6 shadow-lg">
            <h3
              className="
                mb-4 
                text-base
                font-semibold               
                text-corporate-color
                phone:text-base
                tablet:text-lg
                desktop:text-xl"
            >
              会社方針
            </h3>
            <ul
              className="              
                list-none 
                space-y-4
                pl-5
                text-sm
                phone:text-sm
                tablet:text-base
                desktop:text-lg 
            "
            >
              <li className="flex items-start">
                <span className="mr-2 text-corporate-color">•</span>
                <p>
                  私たちは多角度視点アプローチ、既成概念に捉われないアイデア、積み上げられた知見にて、お客様の難題、課題をスピーディーに解決し、事業の発展に貢献します。
                </p>
              </li>
              <li className="flex items-start">
                <span className="mr-2 text-corporate-color">•</span>
                <p>
                  私たちは少数精鋭のプロフェッショナル集団のみで高品質のプロダクトを提供します。
                </p>
              </li>
              <li className="flex items-start">
                <span className="mr-2 text-corporate-color">•</span>
                <p>
                  常に最先端のテーマに挑戦し、クライアントと社会の発展に寄与します。
                </p>
              </li>
              <li className="flex items-start">
                <span className="mr-2 text-corporate-color">•</span>
                <p>
                  常に新しい価値観を創造し、世界中の人が幸せになれるようなサービスを提供します。
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default PolicyPage;
