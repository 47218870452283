import { Helmet } from 'react-helmet-async';
import { Footer } from './Footer';
import Header from './Header';
import Menu from './Menu';
import NewsList from './NewsList';
import SecurityActionField from './SecurityActionField';
import TopicsList from './TopicsList';
import { Outlet } from 'react-router-dom';

const Home = () => {
  return (
    <div className="App bg-white text-gray-800">
      <Helmet>
        <title>株式会社クリエイティブ・イノベーションズ</title>
      </Helmet>

      <Header />
      <main className="">
        <Menu />
        <div className="grid grid-cols-1 gap-8 tablet:grid-cols-2 desktop:grid-cols-2">
          <TopicsList />
          <NewsList />
        </div>
      </main>
      <SecurityActionField />
      <Footer />
      <Outlet />
    </div>
  );
};
export default Home;
