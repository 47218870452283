export default function Header() {
  return (
    <nav className="flex flex-wrap items-center justify-between bg-white p-6">
      <div className="mr-6 flex items-center">
        <a href="/">
          <img
            src="/images/mainlogo.png"
            alt="Creative Innovations Inc.Logo"
            className="mr-2
            h-12
            phone:h-14
            tablet:h-16
            desktop:h-20"
          />
        </a>
      </div>
    </nav>
  );
}
