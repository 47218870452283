import { Helmet } from 'react-helmet-async';
import usePageTracking from '../../hooks/usePageTracking';

const AccessPage: React.FC = () => {
  usePageTracking();
  return (
    <>
      <Helmet>
        <title>
          アクセス&nbsp;-&nbsp;株式会社クリエイティブ・イノベーションズ
        </title>
        <meta name="description" content="アクセス" />
      </Helmet>
      <div className="bg-white text-gray-700">
        <div className="container mx-auto px-4">
          <div className="border-b-4 border-corporate-color py-6 text-center">
            <h2
              className="text-xl
                        font-semibold
                        text-corporate-color
                        phone:text-xl
                        tablet:text-2xl
                        desktop:text-3xl"
            >
              <span>アクセス</span>
            </h2>
          </div>
          <div
            className="
                mb-12
                mt-4
                rounded-lg
                bg-gray-100 
                p-6 
                text-sm   
                shadow           
                phone:text-sm
                tablet:text-base
                desktop:text-lg
                "
          >
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3250.5112318685538!2d139.6296713154188!3d35.44213595065451!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60185cf30fbe9cd1%3A0xf42f3eb41ef31689!2z44CSMjMxLTAwMzMg56We5aWI5bed55yM5qiq5rWc5biC5Lit5Yy66ZW36ICF55S677yV5LiB55uu77yX77yV4oiS77yRIOOCueOCr-OCqOOCoumVt-iAheeUug!5e0!3m2!1sja!2sjp!4v1619845937448!5m2!1sja!2sjp"
              width="100%"
              height="450"
              style={{ border: 0 }}
              loading="lazy"
              className="rounded-lg"
              sandbox="allow-scripts allow-same-origin allow-popups"
            ></iframe>
            <div className="pt-4">
              <p>
                〒231-0033
                <br />
                神奈川県横浜市中区長者町5-75-1スクエア長者町322
                <br />
                <br />
                JR根岸線 関内駅徒 歩7分
                <br />
                横浜市営ブルーライン 伊勢佐木長者町駅 徒歩2分
                <br />
                京浜急行 日ノ出町駅 徒歩9分
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccessPage;
