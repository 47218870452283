import { Helmet } from 'react-helmet-async';

import usePageTracking from '../../hooks/usePageTracking';

const AboutPage: React.FC = () => {
  usePageTracking();

  return (
    <>
      <Helmet>
        <title>
          会社概要&nbsp;-&nbsp;株式会社クリエイティブ・イノベーションズ
        </title>
        <meta name="description" content="会社概要" />
      </Helmet>
      <div className="bg-white text-gray-700 ">
        <div className="container mx-auto px-4">
          <div className="border-b-4 border-corporate-color py-6 text-center">
            <h2
              className="
                text-xl 
                font-semibold
                text-corporate-color
                phone:text-xl
                tablet:text-2xl
                desktop:text-3xl"
            >
              <span>会社概要</span>
            </h2>
          </div>
          <div
            className="
                mb-12
                mt-4
                rounded-lg
               bg-gray-100 
                p-6 
                text-sm   
                shadow           
                phone:text-sm
                tablet:text-base
                desktop:text-lg
                "
          >
            <dl className="divide-y divide-gray-300">
              <div className="pb-2 pt-2">
                <dt className="font-semibold">社名</dt>
                <dd>株式会社クリエイティブ・イノベーションズ</dd>
              </div>
              <div className="pb-2 pt-2">
                <dt className="font-semibold">代表者</dt>
                <dd>
                  代表取締役CEO 牧田 太郎
                  <br />
                  代表取締役CTO 中村 功
                </dd>
              </div>

              <div className="pb-2 pt-2">
                <dt className="font-semibold">所在地</dt>
                <dd>
                  〒231-0033
                  <br />
                  神奈川県横浜市中区長者町5-75-1スクエア長者町322
                  <br />
                  <a href="/access" className="text-blue-500">
                    →アクセス
                  </a>
                </dd>
              </div>

              <div className="pb-2 pt-2">
                <dt className="font-semibold">資本金</dt>
                <dd>２００万円</dd>
              </div>

              <div className="pb-2 pt-2">
                <dt className="font-semibold">設立</dt>
                <dd>２０１４年３月５日</dd>
              </div>

              <div className="pb-2 pt-2">
                <dt className="font-semibold">事業内容</dt>
                <dd>
                  ITコンサルティング
                  <br />
                  IT業務支援
                  <br />
                  システム開発支援
                  <br />
                  上記に付帯又は関連する一切の事業
                </dd>
              </div>

              <div className="pb-2 pt-2">
                <dt className="font-semibold">決算期</dt>
                <dd>２月末（年１回）</dd>
              </div>

              <div className="pb-2 pt-2">
                <dt className="font-semibold">取引銀行</dt>
                <dd>三井住友銀行、ゆうちょ銀行</dd>
              </div>
            </dl>

            <h3 className="mt-6 border-t-2 border-gray-200 pt-4 font-semibold">
              沿革
            </h3>
            <dl className="divide-y divide-gray-300">
              <div className="pb-2 pt-2">
                <dt>2014年3月</dt>
                <dd>神奈川県横浜市にて設立</dd>
              </div>

              <div className="pb-2 pt-2">
                <dt>2014年5月</dt>
                <dd>神奈川県横浜市にて営業開始</dd>
              </div>

              <div className="pb-2 pt-2">
                <dt>2015年7月</dt>
                <dd>神奈川県横浜市中区翁町に本社移転</dd>
              </div>

              <div className="pb-2 pt-2">
                <dt>2021年5月</dt>
                <dd>神奈川県横浜市中区長者町に本社移転</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutPage;
