import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import ModalPage from './components/ModalPage';
import { HelmetProvider } from 'react-helmet-async';
import SecurityPage from './components/contents/SecurityPage';
import PrivacyPage from './components/contents/PrivacyPage';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />}>
            <Route path="/:id" element={<ModalPage />} />
          </Route>
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/security" element={<SecurityPage />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
