import { Helmet } from 'react-helmet-async';
import usePageTracking from '../../hooks/usePageTracking';

const ServicesPage: React.FC = () => {
  usePageTracking();

  return (
    <>
      <Helmet>
        <title>
          サービス／製品一覧 - 株式会社クリエイティブ・イノベーションズ
        </title>
        <meta name="description" content="サービス／製品一覧" />
      </Helmet>
      <div className="bg-white text-gray-700">
        <div className="container mx-auto px-4">
          <div className="border-b-4 border-corporate-color py-6 text-center">
            <h2 className="text-3xl font-semibold">
              <span
                className="text-xl
                        text-corporate-color
                        phone:text-xl
                        tablet:text-2xl
                        desktop:text-3xl"
              >
                サービス
              </span>
            </h2>
          </div>

          <div className="mt-4 rounded-lg bg-gray-100 p-6 shadow">
            <h3
              className="mb-4
                    text-base 
                    font-semibold
                    phone:text-base
                    tablet:text-lg             
                    desktop:text-xl
             "
            >
              サービス一覧
            </h3>

            <div className="mb-6 flex flex-wrap items-start justify-around flex-col phone:flex-col tablet:flex-row desktop:flex-row">
              <img
                src="images/service-s01.jpg"
                alt="ITコンサルティングイメージ"
                className="mb-4 h-24 w-24
                        rounded-lg 
                        shadow-lg
                        phone:h-24 
                        phone:w-24
                        phone:mx-auto
                        tablet:h-32 
                        tablet:w-32 
                        desktop:h-40 
                        desktop:w-40"
              />
              <div className="ml-0 flex-1 phone:ml-0 tablet:ml-6 desktop:ml-6">
                <h4
                  className="mb-2  
                            text-sm 
                            font-semibold
                            phone:text-sm
                            tablet:text-base
                            desktop:text-lg"
                >
                  「ITコンサルティング」
                </h4>
                <div
                  className="mb-2  
                            text-xs                  
                            phone:text-xs
                            tablet:text-sm             
                            desktop:text-base"
                >
                  <p>
                    IT化戦略の策定や、システム要件の取りまとめ、システム導入等、IT化に関わる業務についてのコンサルティング、アドバイスを実施します。
                  </p>
                  <ul className="my-2 ml-5 list-disc">
                    <li>クラウドセキュリティに関するご相談</li>
                    <li>個人情報保護に関するご相談</li>
                    <li>
                      情報セキュリティやクライアントPCのOSバージョンアップ等に関するご相談
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <hr className="my-4" />
            <div className="mb-6 flex flex-wrap items-start justify-around flex-col phone:flex-col tablet:flex-row desktop:flex-row">
              <img
                src="images/service-s02.jpg"
                alt="IT業務支援イメージ"
                className="mb-4 h-24 w-24
                    rounded-lg 
                    shadow-lg
                    phone:h-24 
                    phone:w-24
                    phone:mx-auto
                    tablet:h-32 
                    tablet:w-32 
                    desktop:h-40 
                    desktop:w-40"
              />
              <div className="ml-0 flex-1 phone:ml-0 tablet:ml-6 desktop:ml-6">
                <h4
                  className="mb-2  
                        text-sm 
                        font-semibold
                        phone:text-sm
                        tablet:text-base
                        desktop:text-lg"
                >
                  「IT業務支援」
                </h4>
                <div
                  className="mb-2  
                        text-xs                  
                        phone:text-xs
                        tablet:text-sm             
                        desktop:text-base"
                >
                  <p>
                    IoTやクラウド等の業界のトレンドを使用したモバイルソリューションを効果的に活用していただくために、企業のシステム部門の一員としてお客様のシステム活用をサポートします。
                  </p>
                  <ul className="my-2 ml-5 list-disc">
                    <li>Beacon等のO2Oデバイス関連</li>
                    <li>クラウド</li>
                  </ul>
                </div>
              </div>
            </div>
            <hr className="my-4" />
            <div className="mb-6 flex flex-wrap items-start justify-around flex-col phone:flex-col tablet:flex-row desktop:flex-row">
              <img
                src="images/service-s03.jpg"
                alt="システム開発支援イメージ"
                className="mb-4 h-24 w-24
                    rounded-lg 
                    shadow-lg
                    phone:h-24 
                    phone:w-24
                    phone:mx-auto
                    tablet:h-32 
                    tablet:w-32 
                    desktop:h-40 
                    desktop:w-40"
              />
              <div className="ml-0 flex-1 phone:ml-0 tablet:ml-6 desktop:ml-6">
                <h4
                  className="mb-2  
                        text-sm 
                        font-semibold
                        phone:text-sm
                        tablet:text-base
                        desktop:text-lg"
                >
                  「システム開発支援」
                </h4>
                <div
                  className="mb-2  
                        text-xs
                        phone:text-xs
                        tablet:text-sm
                        desktop:text-base"
                >
                  <p>
                    スマートフォンやタブレットを利用したアプリケーション開発を要件定義や概要設計等の上流工程から、開発やテスト等の下流工程までを弊社にてトータル支援致します。
                  </p>
                  <ul className="my-2 ml-5 list-disc">
                    <li>Webアプリケーション</li>
                    <li>モバイルネイティブアプリケーション</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesPage;
