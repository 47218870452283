import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import usePageTracking from '../../hooks/usePageTracking';

interface CategoryData {
  title: string;
  description: string;
  image: string;
}

interface CategorySelectionProps {
  onSelectCategory: (category: string) => void;
  categories: { [key: string]: CategoryData };
}

const CategorySelection: React.FC<CategorySelectionProps> = ({
  onSelectCategory,
  categories,
}) => {
  const url = '';

  const formatDescription = (description: string) => {
    return description.replace(/\n/g, '<br/>');
  };
  usePageTracking();
  return (
    <div className="">
      <Helmet>
        <title>
          お問い合わせ選択 - 株式会社クリエイティブ・イノベーションズ
        </title>
        <meta name="description" content="お問い合わせ選択" />
      </Helmet>
      <div className="bg-white text-gray-700">
        <div className="container mx-auto px-4">
          <div className="border-b-4 border-corporate-color py-6 text-center">
            <h2
              className="
                    text-xl 
                    font-semibold
                    text-corporate-color
                    phone:text-xl
                    tablet:text-2xl
                    desktop:text-3xl"
            >
              <span>お問い合わせ選択</span>
            </h2>
          </div>
          <div
            className="
                mb-12 
                mt-4 
                rounded-lg 
                bg-gray-100 
                p-6 
                shadow"
          >
            {Object.keys(categories).map((category) => (
              <div
                key={category}
                className="mb-6 flex flex-col items-center space-y-4 
                phone:flex-col 
                phone:items-start 
                phone:justify-around 
                phone:space-x-4 
                phone:space-y-0
                tablet:flex-row desktop:flex-row
                "
              >
                <img
                  src={`${url}/${categories[category].image}`}
                  alt={`${categories[category].title}イメージ`}
                  className="
                        h-32
                        rounded-lg
                        shadow-lg
                        phone:h-28
                        phone:mx-auto
                        tablet:h-40 
                        desktop:h-48"
                />
                <div className="ml-0 flex-1 phone:ml-0 tablet:ml-6 desktop:ml-6">
                  <button
                    onClick={() => onSelectCategory(category)}
                    className="mb-2 
                        block 
                        w-full 
                        rounded 
                        bg-gray-600 
                        p-4 
                        text-xs 
                        text-white shadow
                        hover:bg-corporate-color
                        phone:text-xs
                        tablet:text-sm
                        desktop:text-base
                        "
                  >
                    {categories[category].title}
                  </button>
                  <p
                    className="
                        text-xs
                        text-gray-700
                        phone:text-xs
                        tablet:text-sm
                        desktop:text-base"
                    dangerouslySetInnerHTML={{
                      __html: formatDescription(
                        categories[category].description
                      ),
                    }}
                  ></p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

interface FormData {
  name: string;
  email: string;
  telephone: string;
  message: string;
  category: string;
}

interface ContactFormProps {
  category: string;
  categories: { [key: string]: CategoryData };
  onBack: () => void;
  onSuccess: () => void;
}

const ContactForm: React.FC<ContactFormProps> = ({
  category,
  categories,
  onBack,
  onSuccess,
}) => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    telephone: '',
    message: '',
    category: category,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);
    setErrorMessage(null);

    const url =
      'https://prod-14.japaneast.logic.azure.com:443/workflows/17e9cb97ae474d94a6b1d3da7fdaa281/triggers/HTTPReceived/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2FHTTPReceived%2Frun&sv=1.0&sig=Lk9Cq8XE4bqlsbV2uP7QyBb4vd6kbFnoyzuTABICJ_4';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        onSuccess();
      } else {
        setErrorMessage('送信に失敗しました。');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrorMessage('送信エラーが発生しました。');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {categories[category].title} -
          株式会社クリエイティブ・イノベーションズ
        </title>
        <meta name="description" content="お問い合わせ" />
      </Helmet>
      <div className="mx-auto mb-10 max-w-lg">
        <form
          onSubmit={handleSubmit}
          className="space-y-6 rounded bg-white px-8 pb-8 shadow-md"
        >
          <h2 className="text-3xl font-semibold">
            <span
              className="text-xl
                    text-corporate-color
                    phone:text-xl
                    tablet:text-2xl
                    desktop:text-3xl"
            >
              {categories[category].title}
            </span>
          </h2>
          {errorMessage && (
            <div className="mb-4 text-red-500">{errorMessage}</div>
          )}
          <div>
            <label
              htmlFor="name"
              className="mb-2 block text-sm font-bold text-gray-700"
            >
              お名前（必須）
            </label>
            <input
              type="text"
              id="name"
              name="name"
              required
              className="focus:shadow-outline w-full 
                            appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
              value={formData.name}
              onChange={handleChange}
              placeholder="例）山田 太郎"
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="mb-2 block text-sm font-bold text-gray-700"
            >
              メールアドレス（必須）
            </label>
            <input
              type="email"
              id="email"
              name="email"
              required
              className="focus:shadow-outline w-full appearance-none rounded border 
                            px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
              value={formData.email}
              onChange={handleChange}
              placeholder="例）xxx@xxx.xxx"
            />
          </div>
          <div>
            <label
              htmlFor="telephone"
              className="mb-2 block text-sm font-bold text-gray-700"
            >
              お電話番号
            </label>
            <input
              type="text"
              id="telephone"
              name="telephone"
              className="focus:shadow-outline w-full appearance-none rounded border 
                            px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
              value={formData.telephone}
              onChange={handleChange}
              placeholder="例）03-1234-5678"
            />
          </div>
          <div>
            <label
              htmlFor="message"
              className="mb-2 block text-sm font-bold text-gray-700"
            >
              内容（必須）
            </label>
            <textarea
              id="message"
              name="message"
              required
              className="focus:shadow-outline h-60 w-full appearance-none rounded border 
                            px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              disabled={isSubmitting}
              className={`focus:shadow-outline rounded px-4 py-2 font-bold text-white focus:outline-none ${
                isSubmitting ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-700'
              }`}
            >
              {isSubmitting ? (
                <div className="inline-block h-5 w-5 border-4 border-white border-t-transparent rounded-full animate-spin"></div>
              ) : (
                '送信'
              )}
            </button>
            <button
              type="button"
              onClick={onBack}
              className="focus:shadow-outline rounded bg-gray-500 px-4 py-2 font-bold text-white hover:bg-gray-700 focus:outline-none"
              disabled={isSubmitting}
            >
              戻る
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

const SuccessMessage: React.FC = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/'); // ルートURLに戻る
  };

  return (
    <div className="mx-auto mb-10 max-w-lg text-center">
      <Helmet>
        <title>送信完了 - 株式会社クリエイティブ・イノベーションズ</title>
        <meta name="description" content="送信完了" />
      </Helmet>
      <div className="mb-10 bg-white text-gray-700">
        <div className="container mx-auto px-4">
          <div className="border-b-4 border-corporate-color py-6 text-center">
            <h2
              className="text-xl
              font-semibold
                    text-corporate-color
                    phone:text-xl
                    tablet:text-2xl
                    desktop:text-3xl"
            >
              <span>送信完了</span>
            </h2>
          </div>
          <div className="mt-4 rounded-lg bg-gray-100 p-6 shadow">
            <h3 className="mb-4 text-xl font-semibold">
              お問い合わせありがとうございます。
            </h3>
            <p>担当者が確認次第、ご連絡いたします。</p>
            <button
              onClick={handleBack}
              className="mt-6 rounded bg-gray-600 p-4 px-6 text-white shadow hover:bg-gray-800"
            >
              戻る
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ContactPage: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [categories, setCategories] = useState<{ [key: string]: CategoryData }>(
    {
      partner: { title: '', description: '', image: '' },
      recruit: { title: '', description: '', image: '' },
      general: { title: '', description: '', image: '' },
    }
  );

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const url = '';
        const categoriesData = await Promise.all(
          Object.keys(categories).map(async (category) => {
            const response = await fetch(`${url}/${category}.json`);
            const data = await response.json();
            return { [category]: data };
          })
        );

        const categoriesMap = categoriesData.reduce(
          (acc, curr) => ({ ...acc, ...curr }),
          {}
        );
        setCategories(categoriesMap);
      } catch (error) {
        console.error('Failed to fetch category data', error);
      }
    };

    fetchCategoryData();
  }, []);

  const handleCategorySelect = (category: string) => {
    setSelectedCategory(category);
  };
  const handleBack = () => {
    setSelectedCategory(null);
    setIsSuccess(false);
  };
  const handleSuccess = () => {
    setIsSuccess(true);
  };

  return (
    <div>
      {isSuccess ? (
        <SuccessMessage />
      ) : selectedCategory ? (
        <ContactForm
          category={selectedCategory}
          categories={categories}
          onBack={handleBack}
          onSuccess={handleSuccess}
        />
      ) : (
        <CategorySelection
          onSelectCategory={handleCategorySelect}
          categories={categories}
        />
      )}
    </div>
  );
};

export default ContactPage;
