export function Footer() {
  return (
    <footer className="flex flex-wrap justify-between bg-corporate-color px-8 py-4">
      <div className="flex space-x-4 text-white">
        <a
          href="/privacy"
          className="
            text-xs
            transition duration-300 ease-in-out hover:text-white
            hover:underline
            phone:text-xs 
            tablet:text-sm
            desktop:text-base"
        >
          プライバシーポリシー
        </a>
        <a
          href="/security"
          className="
            text-xs
            transition 
            duration-300
            ease-in-out hover:text-white hover:underline 
            phone:text-xs 
            tablet:text-sm 
            desktop:text-base"
        >
          セキュリティーポリシー
        </a>
      </div>
      <div
        className="
            phone:line-height-relaxed 
            flex-none 
            text-right 
            text-xs 
            text-white             
            phone:text-xs 
            tablet:text-sm 
            desktop:text-base"
      >
        copyright©2024 Creative Innovations Inc. all rights reserved.
      </div>
    </footer>
  );
}
