const SecurityActionField = () => {
  return (
    <div className="flex justify-end bg-white">
      <a
        href="https://www.ipa.go.jp/security/security-action/index.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="/images/security_action_hitotsuboshi-small_color.png"
          alt="security action"
          className="mx-2 my-5 h-20 w-20"
        />
      </a>
    </div>
  );
};
export default SecurityActionField;
