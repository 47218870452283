import { Helmet } from 'react-helmet-async';
import Header from '../Header';
import { Footer } from '../Footer';
import usePageTracking from '../../hooks/usePageTracking';

const AboutPage: React.FC = () => {
  usePageTracking();

  return (
    <div className="App bg-white text-gray-800">
      <Header />
      <Helmet>
        <title>
          セキュリティーポリシー&nbsp;-&nbsp;株式会社クリエイティブ・イノベーションズ
        </title>
        <meta name="description" content="セキュリティーポリシー" />
      </Helmet>
      <div className="mb-10 bg-white text-gray-700">
        <div className="container mx-auto px-4">
          <div className="border-b-2 border-gray-200 py-6 text-center">
            <h2
              className="
            font-semibold
            text-xl 
            text-corporate-color
            phone:text-xl
            tablet:text-2xl
            desktop:text-3xl"
            >
              <span className="text-corporate-color">
                セキュリティーポリシー
              </span>
            </h2>
          </div>

          <div
            className="mt-4 rounded-lg bg-gray-100 p-6 shadow
              text-sm  
              phone:text-sm
              tablet:text-base
              desktop:text-lg
            "
          >
            <p className="mb-4">
              株式会社クリエイティブ・イノベーションズ（以下、当社という）は、お客様に情報システムサービスを提供する事業者として、取扱うあらゆる情報を保護することが、当社の社会的責務と考えています。当社は、情報セキュリティを確立することを、重要且つ継続的な課題として取り組むこととし、以下に掲げる当社自らの保護規律を規範とし、それを実践するための体制を確立し、当社の事業内容を考慮した適切な保護・管理体制を定着させることを宣言します。
            </p>

            <h3
              className="
                mb-2 
                font-semibold
                text-base
                phone:text-base
                tablet:text-lg
                desktop:text-xl            
                "
            >
              情報セキュリティの定義
            </h3>
            <p>
              情報セキュリティとは、情報の機密性、完全性、可用性の維持を確保することと定義します。
            </p>

            <h3
              className="
                mb-2 
                font-semibold
                text-base
                phone:text-base
                tablet:text-lg
                desktop:text-xl            
                "
            >
              情報セキュリティの適用範囲
            </h3>
            <p>
              当社の適用範囲の管理下にある、すべての業務活動に関わる情報を対象とします。
            </p>

            <h3
              className="
                mb-2 
                font-semibold
                text-base
                phone:text-base
                tablet:text-lg
                desktop:text-xl            
                "
            >
              情報セキュリティ管理体制の構築
            </h3>
            <p>
              事業者として重要な社会的責務を担っていることを当社の役員、従業員が認識し、秘密の厳守はもとより、個人情報保護に関する法律等をはじめとする関連法及び当社が定める事項を遵守し、機密性、完全性、可用性を確保した情報セキュリティ管理体制を構築します。
            </p>

            <h3
              className="
                mb-2 
                font-semibold
                text-base
                phone:text-base
                tablet:text-lg
                desktop:text-xl            
                "
            >
              情報セキュリティ対策を徹底したシステムの実現
            </h3>
            <p>
              情報資産に対する不正な侵入、漏洩、改ざん、紛失、破壊、利用妨害などが発生しないよう、徹底した対策を講じます。
            </p>

            <h3
              className="
                mb-2 
                font-semibold
                text-base
                phone:text-base
                tablet:text-lg
                desktop:text-xl            
                "
            >
              内部監査の実施
            </h3>
            <p>
              定期的に内部監査を実施します。情報セキュリティ対策の実施状況や関連法律の順守状況について調査・点検し、不完全な部分があれば見直しを行います。
            </p>

            <h3
              className="
                mb-2 
                font-semibold
                text-base
                phone:text-base
                tablet:text-lg
                desktop:text-xl            
                "
            >
              情報セキュリティ教育の実施
            </h3>
            <p>
              役員および従業員に対して、情報セキュリティの重要性や情報セキュリティマニュアル等の周知徹底を図るために必要な教育、訓練を実施します。
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutPage;
